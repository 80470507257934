@import 'mixins';
@import 'breakpoints';

.sectionLeftCol {
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include breakpoint(sm) {
    height: auto;
  }
}

.sectionRightCol {
  background-color: var(--color-frost);
  padding: var(--space-lg);

  @include breakpoint(sm) {
    height: auto;
    padding: var(--space-xl);
  }
}

.quoteIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
